import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { PlanType } from "modules/Const";
import { AffiliateDetails, CurrentBrand, UserData } from "types/global";


export const getPartnerDiscount: {
    [key: string]: AffiliateDetails
} = {
    'UM': {
        discountAmount: 0.75,
        partnerId: 'UM',
        partnerName: 'United Masters',
        cookieExpirationFromSignup: 90,
        startDate: '2023-09-26',
        shownPlansOnSignup: [PlanType.LITE, PlanType.PRO],
        defaultRecurringIntervalOnSignup: BillingCycle.MONTHLY,
        planDiscounts: {
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    },

    'Audiomack': {
        discountAmount: 0.75,
        partnerId: 'Audiomack',
        partnerName: 'Audiomack',
        cookieExpirationFromSignup: 90,
        startDate: '2023-11-14',
        shownPlansOnSignup: [PlanType.LITE],
        defaultRecurringIntervalOnSignup: BillingCycle.ANNUAL,
        planDiscounts: {
            [PlanType.LITE]: {
                [BillingCycle.ANNUAL]: 0.75
            },
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    },

    'Daily Playlists': {
        discountAmount: 0.75,
        partnerId: 'Daily Playlists',
        partnerName: 'Daily Playlists',
        cookieExpirationFromSignup: 90,
        startDate: '2024-10-29',
        shownPlansOnSignup: [PlanType.LITE],
        defaultRecurringIntervalOnSignup: BillingCycle.ANNUAL,
        planDiscounts: {
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    },

    'CDBaby': {
        discountAmount: 0.75,
        partnerId: 'CDBaby',
        partnerName: 'CDBaby',
        cookieExpirationFromSignup: 90,
        startDate: '2023-12-10',
        shownPlansOnSignup: [PlanType.LITE, PlanType.PRO],
        defaultRecurringIntervalOnSignup: BillingCycle.MONTHLY,
        planDiscounts: {
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    },
    'Downtown': {
        discountAmount: 0.75,
        partnerId: 'Downtown',
        partnerName: 'Downtown',
        cookieExpirationFromSignup: 90,
        startDate: '2024-06-03',
        shownPlansOnSignup: [PlanType.LITE],
        defaultRecurringIntervalOnSignup: BillingCycle.ANNUAL,
        planDiscounts: {
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    },

    'SoundCloud': {
        discountAmount: 0.75,
        partnerId: 'SoundCloud',
        partnerName: 'SoundCloud',
        cookieExpirationFromSignup: 90,
        startDate: '2025-02-05',
        shownPlansOnSignup: [PlanType.LITE],
        defaultRecurringIntervalOnSignup: BillingCycle.ANNUAL,
        planDiscounts: {
            [PlanType.LITE]: {
                [BillingCycle.ANNUAL]: 0.75
            },
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    },

    'Symphonic': {
        discountAmount: 0.75,
        partnerId: 'Symphonic',
        partnerName: 'Symphonic',
        cookieExpirationFromSignup: 90,
        startDate: '2025-02-05',
        shownPlansOnSignup: [PlanType.LITE],
        defaultRecurringIntervalOnSignup: BillingCycle.ANNUAL,
        planDiscounts: {
            [PlanType.LITE]: {
                [BillingCycle.ANNUAL]: 0.75
            },
            [PlanType.PRO]: {
                [BillingCycle.MONTHLY]: 0.75,
                [BillingCycle.ANNUAL]: 0.75
            }
        }
    }
}


/**
 * Function to check if a user is a partner and get their affiliate details.
 * 
 * @param {Object} userData - The user data object.
 * @returns {Object|null} Returns the affiliate details if the user is a partner, otherwise returns null.
 */
export function userSignedUpFromPartner(userData?: UserData | null | undefined) {
    if (!userData) return null
    if (userData.metadata && userData.metadata.affiliate_details) {
        return userData.metadata.affiliate_details;
    }
    return null;
}

/**
 * Gets the affiliate partner details for the current brand if they exist.
 * First checks if the brand has a partnerId in its affiliate_details and looks up corresponding discount.
 * If no brand partner details found, falls back to checking the user's metadata for affiliate details.
 * @param {CurrentBrand} currentBrand - The current brand object
 * @param {UserData} userData - The current user data
 * @returns {AffiliateDetails | null} Partner details object containing discount info and other partner metadata if found
 */
export const getAffiliatePartnerDetails = ({
    currentBrand,
    userData
}: {
    currentBrand?: CurrentBrand | null | undefined,
    userData?: UserData | null | undefined
}): AffiliateDetails | null => {
    if (!currentBrand && !userData) return null;

    // Check currentBrand first
    if (currentBrand?.affiliate_details?.partnerId) {
        const partnerDetails = getPartnerDiscount[currentBrand.affiliate_details.partnerId];
        if (partnerDetails) {
            return partnerDetails;
        }
    }

    console.log("currentBrand", currentBrand)
    console.log("userData", userData)

    // Then check userData
    if (userData?.metadata?.affiliate_details?.partnerId) {
        const partnerDetails = getPartnerDiscount[userData.metadata.affiliate_details.partnerId];
        if (partnerDetails) {
            return partnerDetails;
        }
    }

    return null;
};

/** 
 * Gets the discount amount for a given subscription type and billing cycle based on partner discounts
 * @param {PlanType} subscriptionType - The plan type (PRO or LITE)
 * @param {BillingCycle} billingCycle - The billing cycle (monthly or annual)
 * @returns {number} The discount amount (0-1) or 0 if no discount applies
 */
export const calculatePartnerDiscountAmount = ({
    currentBrand,
    userData,
    subscriptionType,
    billingCycle
}: {
    currentBrand?: CurrentBrand | null | undefined,
    userData?: UserData | null | undefined,
    subscriptionType: PlanType,
    billingCycle: BillingCycle
}): number => {
    const partnerDetails = getAffiliatePartnerDetails({
        currentBrand,
        userData
    });
    
    if (!partnerDetails?.planDiscounts) return 0;

    const planDiscounts = partnerDetails.planDiscounts;
    if (subscriptionType !== PlanType.PRO && subscriptionType !== PlanType.LITE) return 0;

    const planDiscount = planDiscounts[subscriptionType];
    if (!planDiscount) return 0;

    return planDiscount[billingCycle] || 0;
};