import useScreen from "Hooks/useScreen";
import { LeftColumn, LottieContainer } from "../style";
import {
    EnterArtistContainer,
    EnterArtistLabel,
    LogoContainer,
    MainTitle,
    PlatformLogoContainer,
    RightColumn,
    SubTitle,
    Titles,
} from "../style"
import { OnboardingAccountTypes, useOnboarding } from "../../../../Hooks/OnboardingContext";
import BackButton from "./BackButton";
import { ReactComponent as SpotifyIcon } from "assets/images/streaming/icons/spotify-i.svg";
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import { ReactComponent as TiktokLogo } from 'assets/images/tiktok-black.svg'
import { ReactComponent as InstagramLogo } from 'assets/images/instagram-bordered-icon.svg'
import { userSignedUpFromPartner } from "helpers/partners";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { ChangeEvent, CSSProperties, useContext, useState, useEffect, useCallback } from "react";
import Lottie from 'react-lottie';
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import { debounce } from 'lodash';
import { searchSpotifyArtists } from 'services/symphonyApi/spotifyService';
import { SpotifyArtistSearchResponse } from "types/global";
import ArtistList from "./ArtistList/ArtistList";
import { checkIfSpotifyArtistExistsForUser } from "services/symphonyApi/brandService";
import TikTokConnectModal from "./ConnectPlatformModal/TikTokConnectModal";
import TextInputSymphony from "components/shareable/TextInputSymphony";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import { ReactComponent as EditIcon } from "assets/images/edit.svg"
import InstagramConnectModal from "./ConnectPlatformModal/InstagramConnectModal";
import PrimaryButtonContainer from "./NewOnboarding/PrimaryButtonContainer";
import AlreadyExistingArtist from "./ConnectPlatformModal/AlreadyExistingArtist";
import SignupWithoutConnections from "./ConnectPlatformModal/SignupWithoutConnections";
import NotFoundArtistLabel from "./NotFoundArtistLabel";

const DESCRIPTION_INIT_TEXT = `Setup your account and grow your fanbase with Symphony.`

const FindProfile = () => {
    const { mobileView, mStyle } = useScreen();
    const { currentUser } = useContext(CurrentUserContext)
    const {
        showBackButton,
        handleGoBack,
        isFirstTimeOnboarding,
        currentAccountType,
        handleGoNext,
        selectedSpotifyArtist,
        setSelectedSpotifyArtist,
        setSelectedTikTokProfile,
        selectedTikTokProfile,
        setSelectedInstagramProfile,
        selectedInstagramProfile,
        signupWithoutProfileName,
        setSignupWithoutProfileName,
    } = useOnboarding();

    /** Search string entered by user */
    const [searchStr, setSearchStr] = useState<string>("");
    /** Loading state for search operations */
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    /** Results from artist search */
    const [searchResults, setSearchResults] = useState<Array<SpotifyArtistSearchResponse>>([]);
    /** Source of search results */
    const [searchSource, setSearchSource] = useState<string>("");

    /** Whether the "Artist already exists" modal is shown */
    const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState<boolean>(false);


    /**
     * Modal states for non-Spotify connections
     */
    const [showTiktokConnectModal, setShowTiktokConnectModal] = useState<boolean>(false);
    const [showInstagramConnectModal, setShowInstagramConnectModal] = useState<boolean>(false);
    const [showSignupWithoutConnectionsModal, setShowSignupWithoutConnectionsModal] = useState<boolean>(false);

    /** Handles input change and triggers debounced search */
    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setSearchStr(value);
        if (value.length) {
            setLoadingSearch(true);
            debouncedSearchArtists(value);
        } else {
            setSearchResults([]);
            setSearchSource("");
            setLoadingSearch(false);
            setSelectedSpotifyArtist(null)
        }
    };

    /** Debounced function to search artists via API */
    const debouncedSearchArtists = useCallback(
        debounce(async (searchValue: string) => {
            setLoadingSearch(true);
            try {
                const artists = await searchSpotifyArtists(searchValue, 30);
                setSearchResults(artists);
                setSearchSource("spotify");
            } catch (error) {
                console.error("Error fetching artists:", error);
                setSearchResults([]);
            } finally {
                setLoadingSearch(false);
            }
        }, 1200),
        [] // empty dependency array => stable reference
    );

    /** Cleanup debounce on unmount */
    useEffect(() => {
        return () => {
            debouncedSearchArtists.cancel();
        };
    }, []);

    let titleText = 'Get Started'
    let descriptionText = DESCRIPTION_INIT_TEXT
    let partnerType = userSignedUpFromPartner(currentUser)
    console.log("partnerType", partnerType)
    if (partnerType
        // && !showBackButton
    ) {
        switch (partnerType.partnerId) {
            case 'UM':
                descriptionText = "We're excited to support the UnitedMasters SELECT community. Setup your account to start growing your fanbase with Symphony."
                break
            case 'Audiomack':
                descriptionText = "We're excited to support the Audiomack community. Setup your account to start growing your fanbase with Symphony."
                break
            case 'CDBaby':
                descriptionText = "We're excited to support the CDBaby community. Setup your account to start growing your fanbase with Symphony."
                break
            case 'Daily Playlists':
                descriptionText = "We're excited to support the Daily Playlists community. Setup your account to start growing your fanbase with Symphony."
                break
            case 'Downtown':
                descriptionText = "We're excited to support the Downtown Music community. Setup your account to start growing your fanbase with Symphony."
                break
            case 'SoundCloud':
                descriptionText = "We're excited to support the SoundCloud community. Setup your account to start growing your fanbase with Symphony."
                break
            case 'Symphonic':
                descriptionText = "We're excited to support the Symphonic community. Setup your account to start growing your fanbase with Symphony."
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (selectedSpotifyArtist || signupWithoutProfileName) {
            handleGoNext()
        }
    }, [selectedSpotifyArtist, signupWithoutProfileName])

    useEffect(() => {
        console.log("showAlreadyExistsModal >>>>", showAlreadyExistsModal)
    }, [showAlreadyExistsModal])

    const selectArtist = async (artist: SpotifyArtistSearchResponse) => {
        // 1. Check if the artist already exists for user
        // 2. if so, show an "Already exists" modal
        // 3. if not, continue thru creation flow 
        console.log("selectArtist >>>>", artist)
        try {
            // 1. check if the artist exists for user
            const res = await checkIfSpotifyArtistExistsForUser(artist?.id || null)
            const {
                data: alreadyExistingProfile,
                error: alreadyExistingProfileError
            } = res
            console.log("res >>>>", res)
            console.log("alreadyExistingProfile >>>>", alreadyExistingProfile)
            if (alreadyExistingProfile || alreadyExistingProfileError) {
                console.log("HERE 1 >>>>")
                setShowAlreadyExistsModal(true)
            } else {
                // 3. if not, the artist exists and the user can continue thru creation flow 
                setSelectedSpotifyArtist(artist)
                setShowAlreadyExistsModal(false)
            }
        } catch (e) {
            console.log("error checking if artist exists", e)
        }
    }

    let basicTextOptions = {
        titleText: 'Get Started',
        descriptionText: DESCRIPTION_INIT_TEXT,
        sectionTitle: 'Enter your artist name or Spotify URL',
    }

    if (isFirstTimeOnboarding) {
        if (currentAccountType === OnboardingAccountTypes.CREATOR) {
            basicTextOptions = {
                titleText: 'Get Started',
                descriptionText: DESCRIPTION_INIT_TEXT,
                sectionTitle: `Let's setup your profile. Enter your artist name or Spotify URL.`,
            }
        } else {
            basicTextOptions = {
                titleText: 'Get Started',
                descriptionText: DESCRIPTION_INIT_TEXT,
                sectionTitle: `Let's setup your first profile. Enter your artist name or Spotify URL.`,
            }
        }
    }

    const renderBackButton = () => {
        return (
            <BackButton
                className="backButtonFindProfile"
                onBack={handleGoBack}
                text={!isFirstTimeOnboarding ? 'Back to dashboard' : undefined}
            />
        )
    }

    return (
        <>
            <LeftColumn className="leftColumnFindProfile" width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton() && mobileView &&
                    renderBackButton()
                }
                <LogoContainer className="logoContainerFindProfile">
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton() && !mobileView &&
                        renderBackButton()
                    }
                    <MainTitle className="mainTitleFindProfile">{titleText}</MainTitle>
                    <SubTitle className="subTitleFindProfile">{descriptionText}</SubTitle>
                </Titles>
                <EnterArtistContainer className="enterArtistContainer">
                    <EnterArtistLabel className="enterArtistLabel">
                        {basicTextOptions.sectionTitle}
                    </EnterArtistLabel>
                    <div className="artistInputContainer" style={sty.artistInputContainer}>
                        <PlatformLogoContainer backgroundColor='#191919' padding={mobileView ? '8px' : '13px'}>
                            <SpotifyIcon height={mobileView ? '32px' : '38px'} width={mobileView ? '32px' : '38px'} />
                        </PlatformLogoContainer>
                        <TextInputSymphony
                            value={searchStr}
                            onChange={handleChange}
                            loading={loadingSearch}
                            placeholder="Bad Bunny, SZA..."
                            fullWidth
                        />
                    </div>
                    {searchResults && searchResults.length > 0 &&
                        <ArtistList
                            className="artistListFindProfile"
                            selectedArtistIsLoading={false}
                            artists={searchResults}
                            selectArtist={selectArtist}
                            selectedArtist={null}
                        />
                    }
                    {!loadingSearch && searchStr &&
                        <NotFoundArtistLabel
                            className="notFoundArtistLabelFindProfile"
                            searchStr={searchStr}
                            onClickCreateNewProfile={() => {
                                setSignupWithoutProfileName(searchStr)
                                // handleGoNext()
                            }}
                        />
                    }
                </EnterArtistContainer>
                <div
                    className="anotherSocialPlatformContainer"
                    style={sty.anotherSocialPlatformContainer}
                >
                    <div
                        className="createAccountWithoutSpotifyText"
                        style={sty.createAccountWithoutSpotifyText}
                    >
                        Or create your account with another social platform:
                    </div>
                    <div style={mStyle(sty, "platformsButtonsContainer")}>
                        <ButtonSymphony
                            width={mobileView ? "100%" : "auto"}
                            variant="outlined"
                            onClick={() => setShowTiktokConnectModal(true)}

                            iconLeft={
                                <div>
                                    <TiktokLogo width={24} height={24} />
                                </div>
                            }
                            iconRight={
                                selectedTikTokProfile ? <EditIcon /> : undefined
                            }
                        >
                            {selectedTikTokProfile ? `@${selectedTikTokProfile.username}` : 'Connect TikTok'}
                        </ButtonSymphony>
                        <ButtonSymphony
                            width={mobileView ? "100%" : "auto"}
                            variant="outlined"
                            onClick={() => setShowInstagramConnectModal(true)}
                            iconRight={
                                selectedInstagramProfile ? <EditIcon /> : undefined
                            }
                            iconLeft={
                                <div style={{
                                }}>
                                    <InstagramLogo width={24} height={24} />
                                </div>
                            }
                        >
                            {selectedInstagramProfile ? `@${selectedInstagramProfile.username}` : 'Connect Instagram'}
                        </ButtonSymphony>

                    </div>
                    {!selectedTikTokProfile && !selectedInstagramProfile &&
                        <ButtonSymphony
                            width={mobileView ? "100%" : "auto"}
                            style={{
                                marginTop: "16px",
                                fontWeight: 200,
                                fontSize: "16px",
                            }}
                            variant="text"
                            onClick={() => {
                                setShowSignupWithoutConnectionsModal(true)
                            }}
                        >
                            I don't have any of these
                        </ButtonSymphony>
                    }
                </div>

                {/* only show continue button if the user has connected a tiktok or instagram profile */}
                {(selectedTikTokProfile || selectedInstagramProfile) && (<PrimaryButtonContainer>
                    <ButtonSymphony
                        width={mobileView ? "100%" : "300px"}
                        disabled={!selectedTikTokProfile && !selectedInstagramProfile}
                        onClick={() => {
                            handleGoNext()
                        }}>Continue
                    </ButtonSymphony>
                </PrimaryButtonContainer>)}
            </LeftColumn>
            <RightColumn className="rightColumnAddArtist" display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
            <TikTokConnectModal
                className="tikTokConnectModalFindProfile"
                open={showTiktokConnectModal}
                closeModal={() => setShowTiktokConnectModal(false)}
                selectedTikTokProfile={selectedTikTokProfile}
                selectTikTokProfile={(selectedTiktokArtist) => {
                    setSelectedTikTokProfile(selectedTiktokArtist);
                    // handleGoNext();
                }}
            />
            <InstagramConnectModal
                className="instagramConnectModalFindProfile"
                open={showInstagramConnectModal}
                closeModal={() => setShowInstagramConnectModal(false)}
                selectedInstagramProfile={selectedInstagramProfile}
                selectInstagramProfile={(selectedInstagramProfile) => {
                    setSelectedInstagramProfile(selectedInstagramProfile);
                    // handleGoNext();
                }}
            />
            <SignupWithoutConnections
                className="signupWithoutConnectionsModalFindProfile"
                open={showSignupWithoutConnectionsModal}
                closeModal={() => setShowSignupWithoutConnectionsModal(false)}
                onSubmit={(name: string) => {
                    setSignupWithoutProfileName(name)
                    // handleGoNext()
                }}
            />
            {selectedSpotifyArtist && showAlreadyExistsModal &&
                <AlreadyExistingArtist
                    className="alreadyExistingArtistModalFindProfile"
                    isOpen={showAlreadyExistsModal}
                    closeModal={() => setShowAlreadyExistsModal(false)}
                    artist={selectedSpotifyArtist}
                />
            }
        </>
    )
}
const sty: Record<string, CSSProperties> = {
    anotherSocialPlatformContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        gap: "8px"
    },
    platformsButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "16px"
    },
    platformsButtonsContainer_mobile: {
        flexDirection: "column",
        gap: "8px",
        width: "100%"
    },
    createAccountWithoutSpotifyText: {
        fontFamily: 'DIN',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#707070'
    },
    artistInputContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        gap: "16px"
    },
}
export default FindProfile;