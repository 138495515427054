import { useAuth0 } from "@auth0/auth0-react";
import { isSymphonyAdmin } from "helpers/admin";
import getUserByAuth0Id from "layouts/controllers/getUserByAuth0Id";
import { useState, createContext, ReactNode, useEffect, useContext } from "react";
import { getOrganizationData } from "services/symphonyApi/organizationService";
import { AttributionDetails, CurrentBrand, OrganizationData, RewardfulDetails, UserData, UserMetadata } from "types/global";
import * as Sentry from '@sentry/browser';
import { toast } from "react-toastify";
import { SHARED_TOAST_OPTIONS } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import { getUserBrands } from "services/symphonyApi/brandService";
import FB from "../helpers/FB";
import { identify, registerOnce, setUserPropertiesOnce } from "../analytics";
import updateUserMetadata from "services/symphonyApi/userService";
import Logger from "Logger";
import Axios from "helpers/Interceptor";
import { CurrentBrandContext } from "./CurrentBrandContext";

interface UserTrackingType {
  utmParams?: { [key: string]: string };
  partnershipParams?: { [key: string]: string };
  rewardfulParams?: { [key: string]: string };
}

interface Context {
  /** The currently authenticated user */
  currentUser: UserData | undefined;
  /** Updates the current user data */
  setCurrentUser: (user: UserData) => UserData | undefined;
  /** Reloads the current user data from the server */
  reloadUser: () => Promise<UserData> | undefined;
  /** Whether user data is currently being loaded */
  loadingUser: boolean;
  /** The organization data for the current user */
  organization: OrganizationData | undefined;
  /** Updates the organization data */
  setOrganization: (organization: OrganizationData) => void;
  /** Whether organization data is being loaded in admin view */
  loadingOrganizationFromAdminView: boolean;
  /** Sets the spoofed user ID for admin impersonation */
  setSpoofedUserId: (userId: number | null) => void;
  /** Currently spoofed user ID, if any */
  spoofedUserId: number | null;
  /** Whether current user has Symphony admin privileges */
  isSymphonyAdmin: boolean;
  /** Fetches brands associated with current user */
  getBrands: (setInitialBrand?: boolean) => Promise<CurrentBrand[] | null>;
  /** Loaded brands associated with current user */
  loadedBrands: CurrentBrand[];
  /** Whether brands are being loaded */
  loadingBrands: boolean;
  /** Initializes the user session and handles authentication */
  initializeUserSession: () => Promise<void>;
  /** Whether the initial session loading is in progress */
  isInitializing: boolean;
}

// Creating a context with a similar structure to the CurrentBrandContext
export const CurrentUserContext = createContext<Context>({
  currentUser: undefined,
  setCurrentUser: () => undefined,
  reloadUser: () => undefined,
  loadingUser: false,
  organization: undefined,
  setOrganization: () => undefined,
  loadingOrganizationFromAdminView: false,
  // For Admin Search + Team Management for Admins
  setSpoofedUserId: () => undefined,
  spoofedUserId: null,
  isSymphonyAdmin: false,
  getBrands: async (setInitialBrand?: boolean) => null,
  loadedBrands: [],
  loadingBrands: false,
  initializeUserSession: async () => undefined,
  isInitializing: true,
});

/** Props for the CurrentUserProvider component */
type Props = {
  /** Optional user data to initialize the provider */
  user?: UserData;
  /** Child components to render */
  children: ReactNode[] | ReactNode;
};

const CurrentUserProvider = ({ children }: Props) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user: auth0User
  } = useAuth0();
  const { setCurrentBrand } = useContext(CurrentBrandContext);
  const [storedUser, setUser] = useState<UserData | undefined>(undefined);
  const [organization, setOrganization] = useState<OrganizationData | undefined>(undefined);
  const [loadingOrganizationFromAdminView, setLoadingOrganizationFromAdminView] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  /**
 * Tracks the currently spoofed user ID, if an admin has chosen to impersonate another user
 * This is null when no spoofing is active
 */
  const [spoofedUserId, setSpoofedUserId] = useState<number | null>(null);

  const [loadedBrands, setLoadedBrands] = useState<CurrentBrand[]>([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [isInitializing, setIsInitializing] = useState(false);

  /** Processes and stores the auth0 token */
  const handleToken = (token: string) => {
    if (!token) {
      Sentry.captureException("Could not get auth0 access token silently");
      logout({ returnTo: window.location.origin });
      return false;
    }
    localStorage.setItem('accessToken', token);
    return true;
  };

  /** Processes cookies for user tracking */
  const processCookies = (): UserTrackingType => {
    // Pull out any cookies or UTM params that we should set on the user
    const savedObject: any = {
      utmParams: {},
      partnershipParams: {},
      rewardfulParams: {},
    };

    const cookies = document.cookie.split(';');

    cookies.forEach(cookieStr => {
      const [key, value] = cookieStr.split('=').map(str => str.trim());

      // For UTM Params
      // if (key.startsWith('utm_')) {
      //   let words: string[] = key.split('_');
      //   words = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
      //   // Saves UTM params as: Initial UTM Source, Initial UTM Medium, etc.
      //   const newKey: string = 'Initial ' + words.join(' ');
      //   savedObject.utmParams[newKey] = value;
      // }


      // Check for UTM parameters and store them in utmParams
      if (key.startsWith('utm_')) {
        savedObject.utmParams[key] = value;
      }

      // Check for 'referrer' cookie and store it in partnershipParams
      if (key === 'referrer') {
        savedObject.partnershipParams[key] = value;
      }

      // Check for Rewardful parameters and store them in rewardfulParams
      if (key === 'rewardful_via' || key === 'rewardful_affiliate_id') {
        savedObject.rewardfulParams[key] = value;
      }
    });

    // Clean up objects to avoid returning empty ones
    if (Object.keys(savedObject.utmParams).length === 0) {
      delete savedObject.utmParams;
    }
    if (Object.keys(savedObject.partnershipParams).length === 0) {
      delete savedObject.partnershipParams;
    }
    if (Object.keys(savedObject.rewardfulParams).length === 0) {
      delete savedObject.rewardfulParams;
    }

    return savedObject;
  };

  /** Sets up user tracking metadata */
  const setUserTrackingMetadata = async (userTrackingDetails: UserTrackingType, user: UserData) => {
    const { utmParams, partnershipParams, rewardfulParams } = userTrackingDetails;

    const existingMetadata: UserMetadata | null = user.metadata || null;
    let metadataUpdateNeeded = false;

    // Preserve all existing metadata by spreading it into newMetadata
    const newMetadata: UserMetadata = {
      ...existingMetadata, // This preserves all existing fields including onboarding_answers
      // New fields will be added below
    } as UserMetadata;

    // Rest of the checks remain the same, but now we're adding to the existing metadata
    const existingReferrer = existingMetadata?.affiliate_referrer;
    if (partnershipParams?.referrer && !existingReferrer) {
      newMetadata.affiliate_referrer = partnershipParams.referrer;
      metadataUpdateNeeded = true;
    }

    // Check and update for UTM data
    const existingAttribution = existingMetadata && existingMetadata.attribution;

    if (utmParams && Object.keys(utmParams).length > 0 && !existingAttribution) {
      newMetadata.attribution = utmParams;
      metadataUpdateNeeded = true;
    }

    // Check and update for Last Touch Attribution UTM data 
    if (utmParams && Object.keys(utmParams).length > 0 && existingAttribution) {
      newMetadata.last_touch_attribution = utmParams;
      metadataUpdateNeeded = true;
    }

    // Check and update for Rewardful data - this should refresh automatically,
    // as rewardful's clientside script will update the cookie on every page load
    //  - only add this if no partner referrer is already present
    if (rewardfulParams && !existingReferrer && (existingMetadata?.rewardful?.rewardful_via !== rewardfulParams.rewardful_via)) {
      newMetadata.rewardful = rewardfulParams;
      metadataUpdateNeeded = true;
    }

    // Update metadata on the server if needed
    if (metadataUpdateNeeded) {
      await updateUserMetadata(user.userid, newMetadata)
    }

    const savedObject: { [key: string]: any } = {
      ...newMetadata,
    };

    // Format and include both original and formatted UTM parameters
    if (newMetadata.attribution) {
      for (const key in newMetadata.attribution) {
        let words = key.split('_');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const formattedKey = 'Initial ' + words.join(' ');

        // Add both original and formatted UTM parameters
        savedObject[key] = (newMetadata.attribution as any)[key];
        savedObject[formattedKey] = (newMetadata.attribution as any)[key];
      }
    }


    // Add affiliate_referrer and formatted partner referral
    if (newMetadata.affiliate_referrer) {
      savedObject['affiliate_referrer'] = newMetadata.affiliate_referrer;
      savedObject['Referred by Partner'] = newMetadata.affiliate_referrer;
    }

    // Add Rewardful data and a boolean for referral status - and save to Mixpanel
    if (newMetadata.rewardful) {
      savedObject['rewardful_via'] = newMetadata.rewardful.rewardful_via;
      savedObject['Was Referred by Other User'] = true;
    } else {
      savedObject['Was Referred by Other User'] = false;
    }

    registerOnce({ ...savedObject });
    setUserPropertiesOnce(savedObject);

  };

  /** Checks Facebook connection status */
  const checkFBStatus = async () => {
    var getFbStatus = new Promise(async (resolve, reject) => {
      const fb = FB();
      if (!fb) {
        const message = 'FB SDK with issues. Contact Support.';
        Sentry.captureException(message);
        resolve(false)
      }
      fb.getLoginStatus((response: any) => {
        if (response && response.status === 'connected') {
          Logger.info('FB User is authorized');
          resolve(true)
        } else {
          Logger.warn('FB User is not authorized');
          resolve(false)
        }
      }, true)
    })

    var loggedIn = await getFbStatus
    // check for user status
    if (loggedIn) {
      try {
        var userData = await Axios.get('/user/me')
        var data = userData.data.data

        if (data && data.facebook && data.facebook.fbTokenValid) {
          // we're good to go
          Logger.debug("Access token is good to go")
        } else {
          // get the FB access token and send to server to save
          const authResp = FB().getAuthResponse()
          if (authResp.accessToken) {
            await Axios.post('/user/me/connect', {
              service: 'facebook',
              value: authResp.accessToken
            })
          }
          Logger.debug("Access token is refreshed, now good to go")
        }
      } catch (e) {
        Logger.warn("Error getting user info. Access token check has issues")
      }
    }
  };

  /** Checks Rewardful referral status */
  const checkRewardfulStatus = () => {
    const rewardful = (window as any).rewardful;
    if (rewardful) {
      rewardful('ready', () => {
        const Rewardful = (window as any).Rewardful;
        if (Rewardful.referral) {
          Logger.debug('Current referral ID: ', Rewardful.referral);
        } else {
          Logger.warn('No referral present.');
        }
      });
    }
  };

  /** Main function to initialize user session */
  const initializeUserSession = async () => {
    // Guard against multiple initializations
    if (isInitializing || storedUser) {
      return;
    }
    // Set loading state while we initialize
    setIsInitializing(true);

    try {
      // If user is authenticated with Auth0
      if (isAuthenticated) {
        // Step 1: Get fresh access token from Auth0
        const token = await getAccessTokenSilently();

        // Step 2: Store token in localStorage if valid
        if (handleToken(token)) {
          // Step 3: Fetch user data from our backend using Auth0 ID
          let userData = await getUserByAuth0Id(auth0User!.sub!);

          // Step 4: Verify Facebook connection status if applicable
          await checkFBStatus();

          // Step 5: Process any UTM/tracking cookies
          const trackingDetails = processCookies();

          // Step 6: Identify user in analytics
          identify({
            id: String(userData.userid),
            email: userData.email || undefined,
            name: undefined
          });


          // Step 8: Save tracking metadata to user profile
          await setUserTrackingMetadata(trackingDetails, userData);
          userData = await getUserByAuth0Id(auth0User!.sub!);
          // Step 7: Update user state in context
          setCurrentUser(userData);
          // Step 9: Check for referral program status
          checkRewardfulStatus();

          // Step 10: Load user's brands if they have verified email
          await getBrands(true);

        }
      } else {
        // Not authenticated - clear any stored data
        localStorage.clear();

        // If not on login page, redirect to Auth0 login
        if (window.location.pathname !== '/auth/login') {
          // Initialize login params object
          const savedObject: any = { screen_hint: 'login' };

          try {
            // Parse current URL for any tracking/UTM params
            const url = new URL(window.location.href);
            const params = url.searchParams;

            // Check for login type hint
            const loginHint = params.get('t');
            if (loginHint) savedObject.screen_hint = loginHint;

            // Save any UTM or click ID params
            params.forEach((value, key) => {
              if (key.startsWith('utm_') || key.startsWith('fbclid') || key.startsWith('ttclid')) {
                savedObject[key] = value;
                localStorage.setItem(key, value);
              }
            });
          } catch (e) {
            Logger.error(e);
          }

          // Redirect to Auth0 login with saved params
          await loginWithRedirect(savedObject);
        }
      }
    } catch (e) {
      // On any error, log and force logout
      Logger.error(e);
      logout({ returnTo: window.location.origin });
    } finally {
      // Always clear loading state when done
      setIsInitializing(false);
    }
  };

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      if (!spoofedUserId) return;
      setLoadingOrganizationFromAdminView(true);
      try {
        const organizationDetails = await getOrganizationData({
          spoofedUserId
        });
        setOrganization(organizationDetails);
      } catch (error) {
        Sentry.captureException(error);
        toast.error('Error fetching organization details', SHARED_TOAST_OPTIONS);
      } finally {
        setLoadingOrganizationFromAdminView(false);
      }
    };

    fetchOrganizationDetails();
  }, [spoofedUserId]);

  /**
   * Allows admins to set a spoofed user ID for testing/support purposes
   * @param userId - The user ID to spoof, or null to clear spoofing
   */
  const handleSetSpoofedUserId = (userId: number | null) => {
    if (!storedUser || !isSymphonyAdmin(storedUser)) {
      console.warn('Attempted to spoof user without admin privileges');
      return;
    }
    setSpoofedUserId(userId);
  };


  const reloadUser = async (): Promise<UserData> => {
    setLoading(true);
    const userData: UserData = await getUserByAuth0Id(auth0User!.sub!)

    setUser(userData);
    setSpoofedUserId(null);

    setLoading(false);

    // If the user has an organization and their subscription isn't canceled,
    // we can trigger the organization setup immediately
    if (userData?.organization && userData?.metadata?.organization_subscription?.status !== 'canceled') {
      setOrganization(userData.organization);
    }
    return userData;
  }

  const setCurrentUser = (user: UserData): UserData | undefined => {
    setUser(user);
    setSpoofedUserId(null);

    // If the user has an organization and their subscription isn't canceled,
    // we can trigger the organization setup immediately
    if (user?.organization && user?.metadata?.organization_subscription?.status !== 'canceled') {
      setOrganization(user.organization);
    }
    return user;
  }

  // called to logout
  const logoutSession = () => logout({ returnTo: window.location.origin })


  /**
   * Fetches the brands associated with the current user.
   * Uses local storage to remember the last selected brand and returns all brands.
   */
  const getBrands = async (setInitialBrand?: boolean): Promise<CurrentBrand[] | null> => {
    try {
      setLoadingBrands(true);
      const brandsArr: CurrentBrand[] = await getUserBrands();
      setLoadedBrands(brandsArr);
      if (brandsArr.length > 0) {
        // If setInitialBrand is true, set the default selected brand in localStorage and context
        if (setInitialBrand) {
          const cachedBrand = localStorage.getItem("selected-brand");
          let selectedBrand: CurrentBrand;

          if (cachedBrand) {
            selectedBrand = brandsArr.find(
              (o) => o.id === parseInt(cachedBrand)
            ) || brandsArr[0];
          } else {
            selectedBrand = brandsArr[0];
          }

          // Save selected brand ID to localStorage
          localStorage.setItem("selected-brand", String(selectedBrand.id));
          setCurrentBrand(selectedBrand);
        }

        return brandsArr;
      }
      return null;
    } catch (err) {
      console.error("Error getting brands", err);
      if ((err as any).response?.status === 500) {
        Sentry.captureException(`Error getting the brands with status 500: ${err}`);
        logoutSession();
      }
      return null;
    } finally {
      setLoadingBrands(false);
    }
  };

  const context = {
    currentUser: storedUser,
    setCurrentUser: setCurrentUser,
    reloadUser: reloadUser,
    loadingUser: loading,
    organization,
    setOrganization,
    loadingOrganizationFromAdminView,

    // For Admin Search + Team Management for Admins
    setSpoofedUserId: handleSetSpoofedUserId,
    spoofedUserId,
    isSymphonyAdmin: storedUser ? isSymphonyAdmin(storedUser) : false,
    getBrands,
    initializeUserSession,
    isInitializing,
    loadedBrands,
    loadingBrands,
  };

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;