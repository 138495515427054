import { CSSProperties, useContext, useEffect } from "react";
import AccountType from "./Components/NewOnboarding/AccountType";
import MarketingExperience from "./Components/NewOnboarding/MarketingExperience";
import MarketingBudget from "./Components/NewOnboarding/MarketingBudget";
import MarketingGoals from "./Components/NewOnboarding/MarketingGoals";
import TeamsTotalCreators from "./Components/NewOnboarding/TeamsTotalCreators";
import FindProfile from "./Components/FindProfile";
import ConnectBrandLoader from "components/Loader/ConnectBrandLoader";
import { useOnboarding } from "../../../Hooks/OnboardingContext";
import { pageView } from "analytics";
import useScreen from "Hooks/useScreen";
import SubscriptionDetails from "components/SubscriptionDetails";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import { PlanType, TAB_ID } from "modules/Const";
import { getAffiliatePartnerDetails } from "helpers/partners";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";

/** Ordered list of tab IDs that define the onboarding flow sequence */
const TAB_ORDER: TAB_ID[] = [
  TAB_ID.ACCOUNT_TYPE,
  TAB_ID.BASIC,
  TAB_ID.MARKETING_EXPERIENCE,
  TAB_ID.TEAMS_TOTAL_CREATORS,
  TAB_ID.MARKETING_BUDGET,
  TAB_ID.MARKETING_GOALS,
  TAB_ID.WELCOME
];

const CreateNewSymphonyProfile = () => {
  const {
    selectedTab,
    showGatheringFanbaseLoader,
    trackOnboardingEvent
  } = useOnboarding()
  const { cssCalc, mobileView } = useScreen()
  const { goToDashboard } = useOnboarding()
  const { currentUser } = useContext(CurrentUserContext)

  useEffect(() => {
    pageView('Create New Profile')
    trackOnboardingEvent("Began Artist Creation", {
      newOnboarding: true
    })
    trackOnboardingEvent("NEW ONBOARDING: Start - Create New Profile")
    trackOnboardingEvent("Start - Create New Profile")
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab]);

  function renderPaywall() {
    const affiliatePartnerDetails = getAffiliatePartnerDetails({
      userData: currentUser
    })
    let defaultPlansToShow = [PlanType.LITE, PlanType.PRO]
    let defaultRecurringInterval = BillingCycle.MONTHLY


    if (affiliatePartnerDetails?.shownPlansOnSignup) {
      defaultPlansToShow = affiliatePartnerDetails.shownPlansOnSignup
    }
    if (affiliatePartnerDetails?.defaultRecurringIntervalOnSignup) {
      defaultRecurringInterval = affiliatePartnerDetails.defaultRecurringIntervalOnSignup
    }

    return (
      <div className="subscriptionDetailsWelcomeContainer" style={sty.subscriptionDetailsWelcomeContainer}>
        <SubscriptionDetails
          onlyShowPlans={defaultPlansToShow}
          defaultRecurringInterval={defaultRecurringInterval}
        />
        <ButtonSymphony
          className="subscriptionDetailsWelcomeSkipButton"
          onClick={() => {
            trackOnboardingEvent("NEW ONBOARDING: Skip Paywall")
            goToDashboard()
          }}
          variant="outlined"
          style={{ marginTop: 24, flexShrink: 0 }}
          size="large"
        >
          Skip for now
        </ButtonSymphony>
        <div className="welcomeBottomSpacer" style={{ width: '100%' }}>&nbsp;</div>
      </div>
    )
  }

  const TAB_COMPONENTS = {
    [TAB_ID.ACCOUNT_TYPE]: <AccountType />,
    [TAB_ID.BASIC]: <FindProfile />,
    [TAB_ID.MARKETING_EXPERIENCE]: <MarketingExperience />,
    [TAB_ID.TEAMS_TOTAL_CREATORS]: <TeamsTotalCreators />,
    [TAB_ID.MARKETING_BUDGET]: <MarketingBudget />,
    [TAB_ID.MARKETING_GOALS]: <MarketingGoals />,
    [TAB_ID.WELCOME]: renderPaywall()
  } as const;
  /** Renders a component section with consistent styling */
  const renderComponentSection = (
    index: number,
    tabId: keyof typeof TAB_COMPONENTS
  ) => {
    const tabComponent = TAB_COMPONENTS[tabId];

    return (
      <div
        className={`createNewSymphonyProfileComponent${index}`}
        style={{
          ...sty.createNewSymphonyProfileComponent,
          height: cssCalc(),
          display: selectedTab !== tabId ? 'none' : 'flex',
        }}
      >
        {tabComponent}
      </div>
    );
  };

  return (
    <>
      {renderComponentSection(1, TAB_ID.ACCOUNT_TYPE)}
      {renderComponentSection(2, TAB_ID.BASIC)}
      {renderComponentSection(3, TAB_ID.MARKETING_EXPERIENCE)}
      {renderComponentSection(4, TAB_ID.TEAMS_TOTAL_CREATORS)}
      {renderComponentSection(5, TAB_ID.MARKETING_BUDGET)}
      {renderComponentSection(6, TAB_ID.MARKETING_GOALS)}
      {renderComponentSection(7, TAB_ID.WELCOME)}
      {showGatheringFanbaseLoader && <ConnectBrandLoader />}
    </>
  );
};

const sty: Record<string, CSSProperties> = {
  createNewSymphonyProfileComponent: {
    backgroundColor: 'white',
    overflowY: 'auto',
  },
  subscriptionDetailsWelcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 24,
    width: '100%',
  },
}

export default CreateNewSymphonyProfile;